@import "~bootstrap/scss/bootstrap-grid.scss"; 

:root {
    // --rand1: 0.#{random(1000000)};
    // --rand2: 0.#{random(1000000)};
    // --rand3: 0.#{random(1000000)};
    --rand1: 1.#{random(1000000)};
    --rand2: 1.#{random(1000000)};
    --rand3: 1.#{random(1000000)};
    --rand4: 1.#{random(1000000)};
    --rand5: 1.#{random(1000000)};
    --rand6: 1.#{random(1000000)};
}

html, body {
    height: 100%;
    margin: 0;
    overflow: hidden;
    font-size: 0.8rem;
    scroll-behavior: smooth;
}

body {
    background-color: #212529;
}


// global for keeping borders on the th not the table
table { 
    border-collapse: separate; 
    border-spacing: 0;
}
.table {
    --bs-table-color-type: 'unset';
    --bs-table-bg: transparent;
}

#__next, #page-container, #content-wrap  {
    margin: 0;
    height: 100%;
    background-color: #fff;
    // position: relative;
    // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.z-index-1 {
    z-index: 1;
}
  
.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.z-index-dropdown {
    z-index: $zindex-dropdown
}
.z-index-sticky {
    z-index: $zindex-sticky
}
.z-index-fixed {
    z-index: $zindex-fixed
}
.z-index-offcanvas {
    z-index: $zindex-offcanvas
}
.z-index-modal {
    z-index: $zindex-modal
}
.z-index-popover {
    z-index: $zindex-popover
}
.z-index-tooltip {
    z-index: $zindex-tooltip
}

#content-wrap {
    padding-bottom: env(safe-area-inset-bottom);
}

.table td, .table th {
    vertical-align: middle;
    cursor: default;
}

th a, th a:hover {
    color: black;
    text-decoration: none;
}

.hide-text-cursor, .disable-selection {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version */
    cursor: pointer;
}

@media (min-width: 1200px){
    .container {
        max-width: 1400px;
    }
}

.base-page {
    margin-top: 1em;
}

.navbar {
    min-height: 40px;
    .nav-link {
        text-align: middle;
        font-size: 14px;
    }
}

// for main nav dropdown
#main-nav-dropdown {
    display: none;
    width: 290px;
    left: 100%;
    min-height: 150px;
    margin: 0.5em;
}
#main-nav-dropdown.show {
    display: flex;
    flex-direction: column;
}

#main-nav-dropdown-mobile {
    display: block;
    width: 100%;
}

#main-nav-dropdown .dropdown-item.active {
    color: #ffffff;
    background-color: #0061bb;
}


#main-nav-dropdown .dropdown-divider, #main-nav-dropdown-mobile .dropdown-divider {
    border-color:rgb(108, 108, 108)
}

.navbar-brand { width: 150px; }

#main-nav a {
    color: rgb(187, 187, 187);
}
#main-nav a:hover {
    color: rgb(222, 222, 222);
}

#main-nav .text-gold { 
    color: rgb(212, 158, 41);
}

#main-nav .text-gold:hover {
    color: rgb(254, 188, 44);
}


#main-nav .main-menu-items.active, .main-menu-items.mobile.active {
    color: #ffffff;
    background-color: #0061bb;
    border-radius: 5px;
}
.main-menu-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.main-menu-items {
    padding: 0.5em 1.5em 0.5em 1.5em;
}
.main-menu-items.mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.main-menu-items.mobile.tabs {
    padding: 1em;
    flex-direction: row;
    justify-content:space-between;
}

.main-menu-items.tabs {
    flex-direction: row;
    justify-content:space-between;
}
.main-menu-items.tabs div:first-of-type {
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
.main-menu-items.tabs div:last-of-type {
    text-align: 'end'
}


//admin tabs css
.tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
}

.tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
}

// card ribbons 
.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}
.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
}
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
}
  
  /* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;
    pointer-events: none;
    z-index: 1;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}
.ribbon-top-left::before {
    top: 0;
    right: 0;
}
.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}
.ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
}

  /* top right dot */
.corner-dot:after {
    content: '';
    height: 12px;
    width: 12px;
    position: absolute;
    border-radius: 50%;
}

.corner-dot:after {
    right: 35px;
    top: 15px;
}
.corner-dot.red:after {
    background: #ff1111;
    box-shadow: inset 0px 0px 10px 2px rgba(255, 0, 0, 0.5),
    0px 0px 10px 2px rgba(131, 12, 12, 0.3);
    animation: pulse-red 1s alternate infinite;
}

.corner-dot.purple:after {
    background: #ac11ff;
    box-shadow: inset 0px 0px 10px 2px rgba(88, 28, 255, 0.5),
    0px 0px 10px 2px rgba(121, 12, 131, 0.3);
    animation: pulse-purple 1s alternate infinite;
}

.corner-dot.blue:after {
    background: #0084ff;
    box-shadow: inset 0px 0px 10px 2px rgba(2, 112, 255, 0.5),
    0px 0px 10px 2px rgba(121, 12, 131, 0.3);
    animation: pulse-blue 1s alternate infinite;
}

.corner-dot.green:after {
    background: #00ff00;
    box-shadow: inset 0px 0px 10px 2px rgba(9, 255, 0, 0.5),
    0px 0px 10px 2px rgba(121, 12, 131, 0.3);
    animation: pulse-green 1s alternate infinite;
}

@keyframes pulse-red {
    0% {
      background: rgba(255,255,255,0.3);
      box-shadow: inset 0px 0px 10px 2px rgba(255, 0, 0, 0.5),
                        0px 0px 5px 2px rgba(141, 5, 5, 0.3);
    }
    100% {
      background: rgba(255,255,255,1);
      box-shadow: inset 0px 0px 10px 2px rgba(255, 0, 0, 0.5),
                        0px 0px 15px 2px rgb(136, 6, 6);
    }
}

@keyframes pulse-purple {
    0% {
      background: rgba(255,255,255,0.3);
      box-shadow: inset 0px 0px 10px 2px rgba(225, 0, 255, 0.5),
                        0px 0px 5px 2px rgba(255, 0, 212, 0.3);
    }
    100% {
      background: rgba(255,255,255,1);
      box-shadow: inset 0px 0px 10px 2px rgba(247, 0, 255, 0.5),
                        0px 0px 15px 2px rgb(116, 5, 131);
    }
  }

@keyframes pulse-blue {
    0% {
      background: rgba(255,255,255,0.3);
      box-shadow: inset 0px 0px 10px 2px rgba(0, 110, 255, 0.5),
                        0px 0px 5px 2px rgba(1, 42, 156, 0.3);
    }
    100% {
      background: rgba(255,255,255,1);
      box-shadow: inset 0px 0px 10px 2px rgba(0, 140, 255, 0.5),
                        0px 0px 15px 2px rgb(4, 14, 107);
    }
}

@keyframes pulse-green {
    0% {
      background: rgba(255,255,255,0.3);
      box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 21, 0.5),
                        0px 0px 5px 2px rgba(2, 116, 17, 0.3);
    }
    100% {
      background: rgba(255,255,255,1);
      box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 13, 0.5),
                        0px 0px 15px 2px rgb(5, 73, 3);
    }
}


//pending or sold ribbons
.grey-ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
}
.grey-ribbon::before,
.grey-ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #4f5253;
}
.grey-ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #4f5253;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
}
  
  /* top left*/
.grey-ribbon-top-left {
    top: -10px;
    left: -10px;
    pointer-events: none;
}
.grey-ribbon-top-left::before,
.grey-ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}
.grey-ribbon-top-left::before {
    top: 0;
    right: 0;
}
.grey-ribbon-top-left::after {
    bottom: 0;
    left: 0;
}
.grey-ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
}

//for input type range in AppForm Component
input[type=range].app-form-range {
    height: 48px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    background-color: #ff000000;
}
input[type=range].app-form-range:focus {
    outline: none;
}
input[type=range].app-form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 23px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #50555C;
    background: #50555C;
    border-radius: 14px;
    border: 0px solid #000000;
}
input[type=range].app-form-range::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 42px;
    width: 50px;
    border-radius: 12px;
    background: #529DE1;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9.5px;
}
input[type=range].app-form-range:focus::-webkit-slider-runnable-track {
    background: #50555C;
}
input[type=range].app-form-range::-moz-range-track {
    width: 100%;
    height: 23px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #50555C;
    background: #50555C;
    border-radius: 14px;
    border: 0px solid #000000;
}
input[type=range].app-form-range::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 42px;
    width: 50px;
    border-radius: 12px;
    background: #529DE1;
    cursor: pointer;
}
input[type=range].app-form-range::-ms-track {
    width: 100%;
    height: 23px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type=range].app-form-range::-ms-fill-lower {
    background: #50555C;
    border: 0px solid #000000;
    border-radius: 28px;
    box-shadow: 1px 1px 1px #50555C;
}
input[type=range].app-form-range::-ms-fill-upper {
    background: #50555C;
    border: 0px solid #000000;
    border-radius: 28px;
    box-shadow: 1px 1px 1px #50555C;
}
input[type=range].app-form-range::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 42px;
    width: 50px;
    border-radius: 12px;
    background: #529DE1;
    cursor: pointer;
}
input[type=range].app-form-range:focus::-ms-fill-lower {
    background: #50555C;
}
input[type=range].app-form-range:focus::-ms-fill-upper {
    background: #50555C;
}

//notification anim
.new-notify {
    background-color: #005bff0d;
    -webkit-transition: background-color 10s ease;
    -moz-transition: background-color 10s ease;
    -o-transition: background-color 10s ease;
    transition: background-color 10s ease;
}

// for react-datepicker for inside modals
.react-datepicker-popper {
    z-index: 2;
    position: fixed;
}
.react-datepicker__time-list-item--disabled {
    display: none;
}
.react-datepicker-wrapper {
    display: unset;
    padding: unset;
    border: unset;
    input {
        padding-left: 2.5em;
    }
}
.react-datepicker__calendar-icon {
    width: auto;
    height: 100%;
    fill: #8f8f8f;
}

//for react-tooltip bug on refresh
.__react_component_tooltip {
    position: fixed;
    opacity: 0 !important;
  
    &.show {
      opacity: 1 !important;
    }
}

.nc-txt {
    padding: .055rem;
}
.banned-txt {
    padding: .04rem;
}

//for stacking modals
@for $i from 1 through 6 {
    $zIndexBackdrop:  #{1000 + (5 * $i)};
    $zIndexContent:  #{1000 + (5 * $i) + 2};
    .modal-backdrop.show:nth-of-type(#{$i}) {
        z-index: $zIndexBackdrop;
    }
    div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
        z-index: $zIndexContent;
        padding: 0px;
    }
}

 //for admin page tabs
.nav-tabs .nav-link {
    border-color: #fff #fff #dee2e6;
}
.invisible-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Add this to align vertically all buttons */
button > div,
button > span,
.dropdown-toggle::after {
  vertical-align: middle;
}

// rc tab
.rc-conversation-box {
    max-height: 800px;
}
.rc-conversation-list {
    height: 100%;
}
@media (min-width: 768px){
    .rc-conversation-box {
        max-height: 65vh;
    }
    .rc-conversation-list {
        max-height: 65vh;
    }
}

.customer-status-timeline {
    // margin-top: -0.7em;
    margin: 0 0.5em 0 0.5em;
}

.customer-status-timeline .customer-status-timeline-item {
    border: solid 1px #eee;
    border-right: none;
    border-left: none;
    margin: 0 0 6px 6px;
    text-align: center;
    position: relative;
    line-height: 24px;
    font-size: 11px;
}

.customer-status-timeline .customer-status-timeline-item:last-child {
    border-right: solid 1px #eee;
}

.customer-status-timeline .customer-status-timeline-item:first-child {
    margin: 0 0 6px 0px;
}


.customer-status-timeline .customer-status-timeline-item:not(:first-child):before {
    background-color: #fff;
    z-index: 2;
    content: "";
    position: absolute;
    height: 17px;
    width: 18px;
    border: solid 1px #eee;
    border-bottom: none;
    border-left: none;
    left: -9px;
    top: 3px;
    transform: rotate(45deg);
}

.customer-status-timeline .customer-status-timeline-item:not(:last-child):after {
    content: "";
    position: absolute;
    height: 17px;
    width: 17px;
    border: solid 1px #eee;
    border-bottom: none;
    border-left: none;
    right: -9px;
    top: 3px;
    transform: rotate(45deg);
    background-color: inherit;
    z-index: 3;
}

.customer-status-timeline .done {
    background-color: #eee;
}

.customer-status-timeline .current {
    background-color: #499f08;
    border-color: #499f08;
    color: #fff;
}

// modal close btn
.modal-header {
    position: relative;
    padding: 0.5em 1em 0.5em 1em;
    // padding: 1em 0 0.5em 0;
}
// .modal-header .btn-close {
//     width:2em;
//     height: 2em;
//     background-color:#d9d9d974;
//     // border: 2px solid #818181d3;
// }
.modal-content .custom-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.4em;
    color: white;
    background-color: #565e64;
    width: 2em;
    height: 2em;
    z-index: 1;
}
.modal-content .custom-close-btn:hover {
    color: #adadad;
    background-color: #32373a;
}
// modal nav items
.modal-content .nav .nav-item {
    height: 3em;
    background-color: #e9e9e9;
    border-radius: 0.25rem;
    margin: 0.25em;
}
.modal-content .nav .nav-item:hover {
    background-color: #bdbec4;
}
.modal-content .nav .nav-item .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
    color: rgb(39, 39, 39);
}
.modal-content .nav .nav-item .nav-link.active {
    color: white;
}
.modal-content .nav .nav-item.dropdown.show .nav-link {
    color: white;
}
.modal-content .nav .nav-item.dropdown.show {
    position: unset;
    color: white;
}


.popover-body {
    max-height: 50vh;
    overflow: auto;
}

// for image gallery component
@import "~react-image-gallery/styles/scss/image-gallery.scss";
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    width: 3em;
}
.image-gallery-left-nav, .image-gallery-right-nav {
    padding: 0 10px;
}
// complete table row
.complete-row {
    background-color: #ABEBC6;
}
.complete-row:hover {
    background-color: #98D3B1;
}

.overflow-y-gradient {
    -webkit-mask-image: linear-gradient(to bottom, black 90%, rgba(255,0,0,0) 100%);
    mask-image: linear-gradient(to bottom, black 90%, rgba(255,0,0,0) 100%);
}
// .menu-overflow-y-gradient {
//     -webkit-mask-image: linear-gradient(to bottom, rgba(255, 0, 0, 0.5) 0%, black 10%, black 90%, rgba(255,0,0,0.5) 100%);
//     mask-image: gradient(to bottom, rgba(255,0,0,0.5) 0%, black 10%, black 90%, rgba(255,0,0,0.5) 100%);
// }
// SCROLLBAR
/* width */
::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
    // border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

// react-bootstrap-typeahead
.rbt-aux .rbt-close {
    margin-top: 0;
}

// copied animation
.animate { animation: disappear 2.5s linear; }

.copyAlert { opacity: 0; }

@keyframes disappear {
  30%  { opacity: 0; }
  60%  { opacity: 1; }
  100% { opacity: 0; }
}

#inventory-gps-dropdown {
    padding: 0;
    text-decoration: none;
}

// blast form
.form-weekdays .form-check {
    margin: 0;
    line-height: 1.2;
}
.form-weekdays .form-check .form-check-input {
    float: unset;
    margin: 0;
}

//pin
.super-pin-btn {
    color: #f88410;
}
.super-pin-btn:hover, .super-pin-btn:active {
    color: #cc6d0e!important;
}

.super-super-pin-btn {
    color: #f88410;
    transition: .3s all ease-in-out;
    animation: 0.5s ease-in-out infinite color-change-trans;
}

.super-super-pin-btn:hover {
    color: #cc6d0e;
    animation: 0.5s ease-in-out infinite color-change;
}
.super-super-pin-btn:focus { 
    outline-style: none;
    box-shadow: none;
    border-color: transparent; 
}
.super-super-pin-btn ~ div {
    animation: 0.5s ease-in-out infinite transY;
}

button:disabled,
button[disabled] .super-super-pin-btn {
    color: unset;
    animation: unset;
}

// .super-pin-btn:hover {
//     color: #cc6d0e;
// }

.pop, .pop:hover {
    animation: pop 0.3s linear 1;
}
@keyframes pop{
    50%  {transform: scale(2);}
}

@keyframes color-change-trans {
    0% {
        color: #f88410;
        transform: scale(1) rotate(45deg) translateY(0px);
        // transform:translateX(0px);
        // transform:translateX(0px);
    }
    20% {
        color: gold;
        transform: scale(0.9) translateY(3px);
        // transform:translateX(-3px);
        // transform:translateY(-3px);
    }
    40% {
        color: indianred;
        transform: scale(0.7) translateY(-3px);
        // transform:translateX(3px);
        // transform:translateY(3px);
    }
    60% {
        color: violet;
        transform: scale(0.9) translateY(3px);
        // transform:translateX(-3px);
        // transform:translateY(-3px);
    }
    80% {
        color: green;
        transform: scale(0.7) translateY(-3px);
        // transform:translateX(3px);
        // transform:translateY(3px);
    }
    100% {
        color: #f88410;
        transform: scale(1) translateY(0px);
        // transform:translateX(-3px);
        // transform:translateY(-3px);
    }
}
@keyframes color-change {
    0% {
        color: #f88410;
    }
    20% {
        color: gold;
    }
    40% {
        color: indianred;
    }
    60% {
        color: violet;
    }
    80% {
        color: green;
    }
    100% {
        color: #f88410;
    }
}
@keyframes transY {
    0% {
        transform: translateY(0px);
    }
    20% {
        transform: translateY(2px);
    }
    40% {
        transform: translateY(-2px);
    }
    60% {
        transform: translateY(2px);
    }
    80% {
        transform: translateY(-2px);
    }
    100% {
        transform: translateY(0px);
    }
}

// for logo click prank
.prank-btn {
    animation: 1.5s ease-in-out infinite prankAnim;
}
@keyframes prankAnim {
    0% {
        transform: skew(0deg, 0deg);
    }
    20% {
        transform: skew(-5deg, 5deg);
    }
    40% {
        transform: skew(10deg, -10deg);
    }
    60% {
        transform: skew(-5deg, 5deg);
    }
    80% {
        transform: skew(5deg, -5eg);
    }
    100% {
        transform: skew(0deg, 0deg);
    }
}

table tr .btn-group .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
}

// removes header footer from print
// @media print {
//     @page {
//         size: auto;   /* auto is the initial value */
//         margin: 0mm;  /* this affects the margin in the printer settings */
//     }
// }
//// FOR PRINT
@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        background-color: white;
    }
    
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: inline-block;
        page-break-after: always;
        break-after: always;
    }
    .no-page-break {
        display: inline-block;
        width: 100%;
        page-break-inside: avoid;
        break-inside: avoid;

    }
    tr {
        break-inside: avoid;
    }
    
    @page {
        size: auto;
        margin: 20mm;
    }
}
////

.table-fire { 
    animation: 1.5s ease-in-out infinite fireAnim;
}
@keyframes fireAnim {
    0% {
        background-color: #dfc2c4;
    }
    50% {
        background-color: #f6333371;
    }
    100% {
        background-color: #dfc2c4;
    }
}


// for action column on tabs>lists
.table-action-btn {
    display: flex;
    justify-content: right;
}
.table-action-btn .btn {
    width: 2.5em;
    height: 2.5em;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.table-action-btn .btn-group .btn {
    width: 2.5em;
    height: 2.5em;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offcanvas {
    z-index: 1045!important;
}

.overflow-auto {
    overflow: overlay!important;
}

#content-table thead {   /* Set header to stick to the top of the container. */
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.81);
  height: 30px;
}

#content-table {
    margin: 0;
}

.calendar-day-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: black;
    text-decoration: none;
    overflow: auto;
    // border: 1px solid #e4e4e4;
}
@include media-breakpoint-down(lg) {
    .calendar-day-container.week-mode {
        height: 30vh;
        display: block;
    }
}

#calendar tbody tr td {
    border: 1px solid #e4e4e4;
    height:10vh;
}

#calendar .start-day, #calendar .end-day {
    background-color: #ededed;
    color: #4b4b4b;
    border: 1px solid #d1d1d1;
}

#calendar .active {
    background-color: #00bbff4b;
    color: rgb(60, 60, 60);
    border: 1px solid #d1d1d1;
}

.alert-notify {
    animation: alert-red 1s;
}

@keyframes alert-red {
    0% {

    }
    50% {
        color: #ff0000ba;
        text-shadow: 0 0 5px #FF0000, 0 0 8px #ff0000af;
    }
}
  

// deals page

$border-color: #e0dfdf;
$row-even-color: #eeeeee;
$row-hover-color: #d4d4d4;

.div-table { 
    display: table;
    width: 100%;
    margin-bottom: 1em;
    border-radius: 5px;
}
.dt-header { 
    display: table-header-group;
    font-weight: bold;
}
.dt-header .dt-row > * {
    border-top: 2px solid $border-color;
}

.dt-body { display: table-row-group; }
.dt-row { 
    display: table-row; 
    background: #fff;
    &:nth-child(2n) { background: $row-even-color; }
}
.dt-row > * { 
    display: table-cell; 
    padding: .5rem .5rem;
    max-width: 500px;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    cursor: default;
    border-right: 2px solid $border-color;
    border-bottom: 2px solid $border-color;
    z-index: 0;
    &:first-child { border-left: 2px solid $border-color; }
}
.dt-footer { 
    display: table-footer-group;
    vertical-align: middle;
    border-color: inherit; 
}

// deal board
#deal-board {
    overflow: auto;
    vertical-align: middle;
}

// top row
#deal-board .dt-header {
    height: 50px;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: white;
}
// first col, header
#deal-board .dt-header .dt-row > div:first-child {
    left: 0;
    z-index: 2;
}
// first col, of row
#deal-board .dt-row > div:first-child {
    font-weight: bold;
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 2;
}

// custom sticky col
#deal-board .dt-header .dt-row > .dt-sticky-col-end {
    right: 0;
    z-index: 2;
    border-left: 2px solid $border-color;
}
#deal-board .dt-row > .dt-sticky-col-end {
    font-weight: bold;
    position: sticky;
    right: 0;
    background-color: white;
    z-index: 2;
    border-left: 2px solid $border-color;
}

#deal-board .dt-body input, #deal-board .dt-body .rbt-input  {
    border-radius: 0;
    border: none;
}

// first col, of row
.show-hover:hover{
    background-color: $row-hover-color
}

// first col, of row
#deal-board .dt-row.show-hover:hover{
    background-color: $row-hover-color
}

// prevent popper error
.dropdown-menu { margin: 0; }


// ticket situations
.tickets {
    background-color: #f9f9f9;
}
// .tickets > div:nth-child(2n) {
//     background-color: #fff5f5
// }
.tickets > div:nth-child(2):hover {
    background-color: #f0f2f2;
}

.text-muted {
    color: #999!important;
}

.muted-hover {
    color: #6c757d;
}
.muted-hover:hover {
    color: #4c545a;
}
.bg-white-hover:hover {
    background-color: #eaeaea;
}
.bg-primary-hover:hover {
    background-color: $indigo;
}

.noselect {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.glow-effect {
    box-shadow: 0 0 10px #D3D3D3;
    transition: all 0.5s ease-in-out;
  }
  
.glow-effect:hover {
    box-shadow: 0 0 20px #b4b4b4;
}

// seasonal
$globe-width:   4px;
$globe-height:  8px;
$globe-spacing: 25px;
$globe-spread: 1px;
$light-off-opacity: 0.2;

.lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
    padding: 0;
    margin: -25px 0 0 1em;
    pointer-events: none;
    li {
        position: relative;
        animation-fill-mode: both; 
        animation-iteration-count:infinite;
        list-style: none;
        padding: 0;
        display: block;
        width: $globe-width;
        height: $globe-height;
        border-radius: 50%;
        margin: $globe-spacing/2;
        display: inline-block;
        background: rgb(45, 247, 0);
        box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgb(0, 247, 54);
        animation-name: flash-1;
        animation-duration: 2s;
        &:nth-child(2n+1) {
            background: rgb(0, 68, 255);
            box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0, 55, 255, 0.5);
            animation-name: flash-2;
            animation-duration: 0.4s;
        }
        &:nth-child(4n+2) {
            background: rgb(255, 0, 72);
            box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgb(247, 0, 29);
            animation-name: flash-3;
            animation-duration: 1.1s;
        }
        &:nth-child(odd) {
            transform: translate(5px, 25%);
            animation-duration: 1.8s;
        }
        &:nth-child(3n+1) {
            animation-duration: 1.4s;
        }
        &:before {
            content: "";
            position: absolute;
            background: #222;
            width: ($globe-width - 2);
            height: $globe-height/3;
            border-radius: 3px;
            top: (0 - ($globe-height/6));
            left: 1px;
        }
        &:after {
            content: "";
            top: (0 - $globe-height/2);
            left: $globe-width - 2;
            position: absolute;
            width: $globe-spacing + 10px;
            // height: ($globe-height/3 * 2);
            height: ($globe-height/2 * 2);
            border-bottom: solid #222 1px;
            border-radius: 100%;
        }
        &:last-child:after {
            content: none;
        }
        &:first-child {
            margin-left: -$globe-spacing;
        }
    }
}
@keyframes flash-1 { 
    0%, 100% { background: rgb(0, 247, 82);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgb(0, 247, 54);} 
    50% { background: rgba(0,247,165,$light-off-opacity);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0, 247, 132, 0.2);}
}
@keyframes flash-2 { 
    0%, 100% { background: rgb(21, 0, 255);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgb(0, 76, 255);} 
    50% { background: rgba(0,255,255,$light-off-opacity);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0, 123, 255, 0.2);}
}
@keyframes flash-3 { 
    0%, 100% { background: rgb(247, 0, 33);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgb(247, 0, 33);} 
    50% { background: rgba(247,0,148,$light-off-opacity);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(247, 0, 33, 0.2);}
}

// EASTER
.eggs {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
    padding: 0;
    margin: 0 0 0.4em 0;
    pointer-events: none;
    display: flex;
    justify-content: space-around;
    li {
        display: block;
        position: relative;
        width: $globe-width*3;
        height: $globe-height*1.8;
        box-shadow: inset -10px -10px rgba(0,0,0,0.1);
        border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
        bottom: 0;
        background-color: #f7d794;
        animation: easter-shake 5s infinite;
    }
    
    li::before {
        content: "";
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        border-radius: 50%;
        // background-color: #f8c291;
        // animation: easter-glow 1s infinite;
    }

    li:nth-child(1) {
        background-color: #f7d794;
        background: repeating-linear-gradient(40deg, #f12711, #f5af19 2px, #f12711 2px, #f5af19 4px);
    }
    
    li:nth-child(2):before {
        background-color: #f3a683;
        background: repeating-linear-gradient(-10deg, #70c1b3, #70c1b3 2px, #247ba0 2px, #247ba0 4px);
    }
    
    li:nth-child(3) {
        background-color: #f8a5c2;
        background: repeating-linear-gradient(20deg, #70c1b3, #70c1b3 2px, #247ba0 2px, #247ba0 4px);
    }
    
    li:nth-child(4) {
        background-color: #63cdda;
        background: repeating-linear-gradient(-30deg, #f6d365, #fda085 2px, #c33764 2px, #1d2671 4px);
    }
    
    li:nth-child(5) {
        background-color: #3c6382;
        background: repeating-linear-gradient(0deg, #f9c74f, #f9c74f 2px,#90be6d 2px, #90be6d 4px);
    }
    
    li:nth-child(6) {
        background-color: #0a3d62;
        background: repeating-linear-gradient(-45deg, #00c6ff, #0072ff 2px, #00c6ff 2px, #0072ff 4px);
    }
    li:nth-child(7) {
        background-color: #0a3d62;
        background: repeating-linear-gradient(50deg, #f43b47, #453a94 2px, #e18e93 2px, #453a94 4px);
    }
}

@keyframes easter-shake {
    0% {
        transform: rotate(0deg);
    }
    5% {
        transform: rotate(20deg);
    }
    12% {
        transform: rotate(0deg);
    }
    15% {
        transform: rotate(-20deg);
    }
    16% {
        transform: rotate(10deg);
    }
    89% {
        transform: rotate(-10deg);
    }
    90% {
        transform: rotate(20deg);
    }
    95% {
        transform: rotate(0deg);
    }
    96% {
        transform: rotate(-20deg);
    }
    97% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes easter-glow {
    0% {
        box-shadow: 0 0 0 0 #34787f;
    }
    50% {
        box-shadow: 0 0 0 10px #BADBD2;
    }
    100% {
        box-shadow: 0 0 0 0 #F47A97;
    }
}

// comment box
.show-delete {
    display: none;
}
.comment-row:hover .show-delete {
    display: inline-block;
}

// .fourth-of-july {
//     color: red;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     // background: linear-gradient(45deg, red, transparent, blue);
//     // background-size: 300% 300%;
//     // animation: fourth-of-july-animation 10s ease infinite;
//     mix-blend-mode: lighten;
//     opacity: 0.5;
// }

// @keyframes fourth-of-july-animation {
//     0% {
//         background-position: 0% 50%;
//     }
//     50% {
//         background-position: 100% 50%;
//     }
//     100% {
//         background-position: 0% 50%;
//     }
// }

.firework {
    position: absolute;
    width: 100%;
    top: 10%;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    // background-color: #ffc228;
    opacity: 0;
    animation: burst 1.5s linear infinite;
}

.circle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background-color: hsl(var(--hue, 0), 100%, 50%);
}

.circle:nth-child(1) {
    animation-delay: calc(0.5s * var(--rand1));
    margin: calc(5px * var(--rand1));
}
.circle:nth-child(2) {
    animation-delay: calc(1s * var(--rand2));
    margin: calc(5px * var(--rand2));
}
.circle:nth-child(2) {
    animation-delay: calc(0.5s * var(--rand3));
    margin: calc(5px * var(--rand3));
}
.circle:nth-child(3) {
    animation-delay: calc(1s * var(--rand4));
    margin: calc(5px * var(--rand4));
}
.circle:nth-child(4) {
    animation-delay: calc(0.5s * var(--rand5));
    margin: calc(5px * var(--rand5));
}
.circle:nth-child(5) {
    animation-delay: calc(0.5s * var(--rand6));
    margin: calc(5px * var(--rand6));
}

@keyframes burst {
    0%, 100% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
}

// variant extention
.text-gold { 
    color: rgb(226, 167, 39);
}
.text-gold a:hover {
    color: rgb(255, 189, 46);
}

.btn-hot { 
    background-color: #ff8a05;
    color: white;
}
.btn-hot:hover { 
    background-color: #da7502;
    color: #ffffff;
    border-color: #da7502;
}
.bg-funded {
    background-color: #1f632f;
    color: white;
}
.bg-muted {
    background-color: #cacaca;
}

.truncate-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Limit to 2 lines */
    max-height: 2.4em; /* Adjust this value according to your font size and line height */
    line-height: 1.2em; /* Adjust this value according to your line height */
}

.row-active-location {
    background-color: #b8daff!important;
}

.deal-board-transaction-list .accordion-button {
    padding: 0.5em 0.1em;
}


// halloween
// .bat svg path {
//     fill: hsl(var(--hue, 0), 100%, 50%);
// }

.halloween {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50px;
    overflow: hidden;
}

.bat {
    position: absolute;
    top: 0;
    width: 6px;
    height: 3px;
    background-color: hsl(var(--hue, 0), 100%, 50%);
    animation: flyHorizontal 40s infinite, flyVertical 20s infinite;
}

.bat::before,
.bat::after {
    content: "";
    position: absolute;
    top: 0px;
    width: 5px;
    height: 8px;
    background-color: hsl(var(--hue, 0), 100%, 50%);
    animation: flap 0.5s infinite alternate;
}

.bat::before {
    left: -6px;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.bat::after {
    right: -6px;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
}

@keyframes flap {
    0%, 100% {
        height: 12px;
        top: -5px;
    }
    50% {
        height: 4px;
        top: 0px;
    }
}

@keyframes flyHorizontal {
    0%, 100% {
        left: 10%;
    }
    50% {
        left: 90%;
    }
}

@keyframes flyVertical {
    0%, 100% {
        top: 40%;
    }
    25% {
        top: 30%;
    }
    50% {
        top: 50%;
    }
    75% {
        top: 40%;
    }
}

// end halloween


/* Christmas theme */
.christmas {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50px;
    overflow: hidden;
}

.snowflake {
    position: absolute;
    top: 20%;
    color: #ffffff; /* White snowflakes */
    font-family: 'Arial', sans-serif; /* Ensuring a common font */
    content: '❅'; /* Snowflake character */
    font-size: 10px; /* Size of the snowflake */
    animation: driftDown 10s infinite, sway 5s infinite alternate;
}

@keyframes driftDown {
    0%, 100% {
        top: 20%;
    }
    50% {
        top: 30%;
    }
}

@keyframes sway {
    0%, 100% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(10px); /* Swaying motion */
    }
}

/* end Christmas theme */
